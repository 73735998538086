
import './App.css';

function App() {
  return (
    <div className="App bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center" style={{height:"100vh"}}>
      <div className='border border-spacing-60 h-60 flex items-center justify-center'>
        <div className='text-center'>
          <h1 className='font-bold text-white text-8xl'>LAUNCHING SOON</h1>
          <p className='text-white'>For more infomation contact us at <a href="mailto:hello@venki.me" className="text-white font-bold">hello@venki.me</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
